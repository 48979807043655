const config = {
    // BASE_URL_API: 'https://zwpf3wivp7.execute-api.ap-northeast-1.amazonaws.com/dev-lae-api/',
    // dev
    // BASE_URL_API: 'https://d2kpjw082nkcyt.cloudfront.net/dev-iriomote-lae-api/',
    // BASE_URL_WEB: 'https://d2kpjw082nkcyt.cloudfront.net',
    // LINE_LINK: 'https://liff.line.me/1657811237-gW1jw86W/',
    // LIFF_ID: '1657811237-gW1jw86W',
    // ADD_FRIEND_LINK: 'https://line.me/R/ti/p/%40271ayaur',
    // prod
    BASE_URL_API: 'https://lae-i.com/prod-iriomote-lae-api/',
    BASE_URL_WEB: 'https://d1l5tnxlx5ukrd.cloudfront.net',
    // BASE_URL_API: 'http://localhost:3000/',
    LINE_LINK: 'https://liff.line.me/2005792562-J09DdWn0/',
    LIFF_ID: '2005792562-J09DdWn0',
    ADD_FRIEND_LINK: 'https://line.me/R/ti/p/%40892npfkt',
    GOOGLE_MAP_API_KEY: 'AIzaSyB2eTL0AZnczp9q2C1sMYsF4DqSIifPT5Y',
    LINE_URL: 'https://line.me/R/',
};

const Environments = {
    baseUrl: config.BASE_URL_API,
    baseUrlWeb: config.BASE_URL_WEB,
    googleMapApiKey: config.GOOGLE_MAP_API_KEY,
    lineLink: config.LINE_LINK,
    lineUrl: config.LINE_URL,
    liffId: config.LIFF_ID,
    addFriendLink: config.ADD_FRIEND_LINK,
};

export default Environments;
